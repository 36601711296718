<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Items Discount Detail</h1>
        </div>
        <div class="title-tabs mt-3">Set up Items Discount</div>
        <b-container class="no-gutters bg-white py-2">
          <div class="py-3">
            <b-row>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Promotion Name"
                  placeholder="Promotion Name"
                  type="text"
                  name="promotions_name"
                  isRequired
                  :isValidate="$v.form.name.$error"
                  :v="$v.form.name"
                  v-model="form.name"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Promotions Ref."
                  placeholder="Promotions Ref."
                  type="text"
                  name="promotions-ref"
                  isRequired
                  :isValidate="$v.form.promotion_ref.$error"
                  :v="$v.form.promotion_ref"
                  v-model="form.promotion_ref"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <div class="mb-3">
                  <label class="main-label"
                    >Discount Value <span class="text-error">*</span></label
                  >
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      :class="{ error: $v.form.promotion_value.$error }"
                      placeholder="Discount"
                      max
                      v-model="$v.form.promotion_value.$model"
                      @keypress="isNumber($event)"
                      oninput="if( this.value.length > 6 )  this.value = this.value.slice(0,6)"
                      @paste.prevent
                    />
                    <div class="input-group-append">
                      <button
                        class="input-group-text"
                        v-if="form.promotion_discount_type_id == 1"
                        @click.prevent="form.promotion_discount_type_id = 2"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'percent']"
                          class="percent"
                        />
                      </button>
                      <button
                        class="input-group-text"
                        v-else
                        @click.prevent="form.promotion_discount_type_id = 1"
                      >
                        <span class="percent">Baht</span>
                      </button>
                    </div>
                  </div>

                  <div v-if="$v.form.promotion_value.$error">
                    <div
                      class="text-danger"
                      v-if="!$v.form.promotion_value.required"
                    >
                      Please input.
                    </div>
                    <div
                      class="text-danger"
                      v-else-if="!$v.form.promotion_value.isValidPromo"
                    >
                      Discount value must less than or equal to 100%
                    </div>
                  </div>
                </div>
              </b-col>
              <div class="break-normal"></div>
              <b-col lg="6" md="6" sm="12" xs="12" class="mb-md-0 mb-3">
                <label class="label-text">
                  Valid From <span class="text-danger">*</span></label
                >

                <div
                  :class="[
                    'input-container',
                    { error: $v.form.start_datetime.$error },
                  ]"
                >
                  <datetime
                    type="datetime"
                    v-model="form.start_datetime"
                    placeholder="Please Select Date"
                    format="dd/MM/yyyy (HH:mm)"
                    value-zone="Asia/Bangkok"
                    @input="handleMinDate"
                    ref="start_datetime"
                  >
                  </datetime>
                  <div
                    :class="
                      'icon-primary text-right ' + id == 0
                        ? ''
                        : 'cursor-default'
                    "
                    @click="$refs.start_datetime.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      :class="
                        id == 0
                          ? 'pointer color-primary'
                          : 'cursor-default color-primary'
                      "
                      color="#B41BB4"
                    />
                  </div>
                </div>
                <div v-if="$v.form.start_datetime.$error">
                  <span
                    class="text-error"
                    v-if="$v.form.start_datetime.required == false"
                    >Please select date time.</span
                  >
                </div>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <label class="label-text">
                  Valid To <span class="text-danger">*</span></label
                >
                <div
                  :class="[
                    'input-container',
                    { error: $v.form.end_datetime.$error },
                  ]"
                >
                  <datetime
                    type="datetime"
                    v-model="form.end_datetime"
                    placeholder="Please Select Date"
                    format="dd/MM/yyyy (HH:mm)"
                    value-zone="Asia/Bangkok"
                    :min-datetime="form.start_datetime"
                    ref="end_datetime"
                  >
                  </datetime>
                  <div
                    :class="
                      'icon-primary text-right ' + id == 0
                        ? ''
                        : 'cursor-default'
                    "
                    @click="$refs.end_datetime.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      :class="
                        id == 0
                          ? 'pointer color-primary'
                          : 'cursor-default color-primary'
                      "
                      color="#B41BB4"
                    />
                  </div>
                </div>
                <div v-if="$v.form.end_datetime.$error">
                  <span
                    class="text-error"
                    v-if="$v.form.end_datetime.required == false"
                    >Please select date time.</span
                  >
                </div>
              </b-col>
            </b-row>
            <hr />
          </div>
          <div class="bg-white">
            <div class="mb-2 main-label">
              Product Promotions <span class="text-danger">*</span>
            </div>
            <b-form-group>
              <b-form-radio-group
                id="radio-group-2"
                v-model="form.is_all_product"
                name="radio-sub-component"
              >
                <b-form-radio value="1"
                  ><span class="text-black">All</span></b-form-radio
                >
                <b-form-radio value="0"
                  ><span class="text-black"
                    >Select a Product Lists</span
                  ></b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
            <ProductSelect
              v-if="form.is_all_product == 0"
              useBy="promotions"
              :filter="filter"
              :fields="fields"
              :items="items"
              :rows="rows"
              :isDisabled="isDisabled"
              :form="form"
              @syncProductSelect="syncProductSelect"
              @pagination="pagination"
              @handleChangeTake="handleChangeTake"
              @handleSearch="handleSearch"
            />
            <div class="text-error" v-if="$v.form.product_id.$error">
              Please select at least 1 product.
            </div>
          </div>
          <section name="branch-select">
            <div class="mb-2 main-label">
              Participating Branches <span class="text-danger">*</span>
            </div>

            <ModalSelectBranch
              ref="ModalSelectBranch"
              @selectBranch="selectBranch"
              :list="branchList"
              :form="form"
              id="1"
              :isBranchDisable="isDisabled"
              text="Select Branch"
            />
          </section>
          <div class="text-error" v-if="$v.form.branch_id.$error">
            Please select at least 1 branch.
          </div>
        </b-container>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.status"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              form.status ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>

        <FooterAction @submit="saveForm()" routePath="/promotions" />
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, requiredIf } from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import OtherLoading from "@/components/loading/OtherLoading";
import ProductSelect from "@/components/ProductSelect";
import ModalSelectBranch from "@/components/promotions/ModalSelectBranch";
export default {
  name: "Detail",
  components: {
    InputText,
    InputSelect,
    OtherLoading,
    ProductSelect,
    ModalSelectBranch,
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        promotion_ref: {
          required,
        },
        promotion_value: {
          required,
          isValidPromo: function (value) {
            if (this.form.promotion_discount_type_id == 1 && value > 100)
              return false;
            return true /*  */;
          },
        },
        start_datetime: { required },
        end_datetime: { required },
        product_id: {
          required: requiredIf(function () {
            return this.form.is_all_product == 0 && this.items.length == 0;
          }),
        },
        branch_id: {
          required: requiredIf(function () {
            return (
              this.form.add_branch_id.length == 0 &&
              this.form.branch_id.length == 0
            );
          }),
        },
        //
      },
    };
  },

  computed: {
    isEdit() {
      return this.$route.params.id == 0 ? false : true;
    },
    isDisabled() {
      return (
        this.$route.params.id != 0 &&
        this.$moment().diff(
          this.$moment(this.form.start_datetime || ""),
          "days"
        ) >= 0
      );
    },
  },
  watch: {},
  data() {
    return {
      isLoading: false,
      branchList: [],
      percentOrbath: false,
      freezeMemberLvl: [],
      form: {
        id: 0,
        allow_other_promotion: 0,
        promotion_discount_type_id: 1, // 1%, 2 baht
        name: null,
        minimum_amount_requirement: 0.0,
        start_datetime: "",
        end_datetime: "",
        promotion_value: "",
        promotion_ref: null,
        status: 0,
        promotion_type_id: 2, //2 item, 3 header
        is_all_product: 0,
        birthday: 0,
        is_center_point: 0,
        point: 0,
        no_point: 0,
        product_group_id: 0,
        branch_group_id: 0,
        product_id: [],
        add_product_id: [],
        delete_product_id: [],
        branch_id: [],
        add_branch_id: [],
        delete_branch_id: [],
        member_level_id: [],
        add_member_level_id: [],
        delete_member_level_id: [],
      },
      filter: {
        search: "",
        page: 1,
        take: 5,
      },
      extraOptions: [
        { text: "Central Point", value: 0 },
        { text: "Branch Point", value: 1 },
      ],
      memberOptions: [],
      birthDayOptions: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ],
      id: this.$route.params.id,
      fields: [
        {
          key: "imageUrl",
          label: "Image",
        },
        {
          key: "description",
          label: "Name/Detail",
          tdClass: "text-left",
        },
        {
          key: "ah_no",
          label: "SKU",
        },
        {
          key: "price",
          label: "Price",
        },
        {
          key: "delete",
          label: "",
        },
      ],
      items: [],
      rows: 0,
      isBusy: false,
      productSelected: [],
      delete_branch_id: [],
      productForm: {
        pageNo: 1,
        // start: 0,
        length: 5,
        promotion_id: this.$route.params.id,
        add_product_id: [],
        delete_product_id: [],
      },
      isBranchDisable: false,
    };
  },
  created() {
    this.getBranchList();

    if (this.isEdit) this.getData();
    this.getMemberLevel();
  },
  destroyed() {},
  methods: {
    handleMinDate() {
      let diff = this.$moment(this.form.end_datetime).diff(
        this.$moment(this.form.start_datetime),
        "min"
      );
      if (diff < 0) this.form.end_datetime = "";
    },
    async getMemberLevel() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberOptions = data.detail;
      }
    },
    async getData() {
      const result = await this.$Axios(
        `${process.env.VUE_APP_API}/promotion/headerItem/${this.id}`
      );
      this.form = result.data.detail;
      this.freezeMemberLvl = result.data.detail.member_level_id;
      this.getProductList();
      this.isBranchDisable = true;
    },

    async syncProductSelect(productList) {
      this.productForm.add_product_id = productList.add_product_id;
      this.productForm.delete_product_id = productList.delete_product_id;
      this.getProductList();
    },
    async getProductList() {
      let payload = { ...this.productForm };

      payload.pageNo = this.filter.page;
      payload.search = this.filter.search;
      payload.length = this.filter.take;

      const result = await this.$Axios.post(
        `${process.env.VUE_APP_API}/promotion/ProductByPromotion`,
        payload
      );
      this.items = result.data.detail.product_list;
      this.rows = result.data.detail.total_product;
    },
    async getBranchList() {
      const result = await this.$Axios(
        `${process.env.VUE_APP_API}/branch/group`
      );
      var branchSelect = [];
      for (let branch of result.data.detail) {
        branch.selected_count = 0;
        for (const check of branch.branchList) {
          if (this.form.branch_id.includes(check.id)) {
            branch.selected_count += 1;
            check.branch_group = branch.name;
            check.is_check = true;
            if (
              this.$route.params.id != 0 &&
              this.$moment().diff(
                this.$moment(this.form.start_datetime || ""),
                "days"
              ) >= 0
            ) {
              check.is_disabled = true;
            }
            branchSelect.push(check);
          }
        }
      }
      // this.rows = branchSelect.length;
      this.branchList = result.data.detail;
    },
    handleSearch() {
      this.getProductList();
    },

    pagination(val) {
      this.filter.page = val;
      this.getProductList();
    },
    handleChangeTake() {
      this.getProductList();
    },

    selectBranch(val) {
      const addID = val.filter((el) => !this.form.branch_id.includes(el));
      const deleteID = this.form.branch_id.filter((i) => !val.includes(i));

      this.form.add_branch_id = addID;
      // this.form.branch_id = val;
      this.form.delete_branch_id = deleteID;
    },

    async saveForm() {
      if (
        this.form.product_id
          .map((el) => this.form.delete_product_id.includes(el))
          .every((el) => el == true)
      ) {
        this.form.product_id = [];
      }
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }
      this.$EventBus.$emit("showLoading");
      const requestPath = this.isEdit
        ? this.$Axios.put(
            `${process.env.VUE_APP_API}/promotion/headerItem`,
            this.form
          )
        : this.$Axios.post(
            `${process.env.VUE_APP_API}/promotion/headerItem`,
            this.form
          );
      const result = await requestPath;

      if (result.data.result) {
        this.successAlert().then(() => this.$router.push("/promotions"));
      } else {
        this.errorAlert(result.data.message);
      }
      this.$EventBus.$emit("hideLoading");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;

      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 46 || charCode > 57 || charCode == 47)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.img-box-preview {
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.reset-password {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  font-size: 12px;
}
::v-deep h2#swal2-title.swal2-title {
  font-size: 24px !important;
}
::v-deep .price-baht {
  &::before {
    content: "฿";
  }
}
::v-deep .font-bold {
  font-weight: bold;
}
.select-branch {
  display: flex;
  width: max-content;
  align-items: center;
}
.panel-select-branch {
  gap: 10px;
  align-items: center;
  display: flex;
}
</style>
